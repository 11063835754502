import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Chat from './Chat';
import '../index.css'; // Use the global index.css

const Game = () => {
  const [user, setUser] = useState(null);
  const [gameState, setGameState] = useState(null);
  const [message, setMessage] = useState('');
  const [buttonMessage, setButtonMessage] = useState('');
  const [buttonClass, setButtonClass] = useState('');
  const [ws, setWs] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      window.location = '/'; // Redirect to home if no token
      return;
    }
  
    // Fetch user info
    axios.get('/api/users/me', {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setUser(res.data);
    }).catch((err) => {
      console.error(err);
      if (err.response && err.response.status === 401) {
        localStorage.removeItem('token');
        window.location = '/'; // Redirect to home if unauthorized
      }
    });
  
    // Fetch current game state
    axios.get('/api/game/current', {
      headers: { Authorization: `Bearer ${token}` }
    }).then((res) => {
      setGameState(res.data);
    }).catch((err) => console.error(err));
  
    // Fetch previous winners
    axios.get('/api/game/winners', {
      headers: { Authorization: `Bearer ${token}` }
    }).then((res) => {
      setGameState(prevState => ({
        ...prevState,
        previousWinners: res.data.slice(0, 3) // Get the latest 3 winners
      }));
    }).catch((err) => console.error(err));
  
    // Setup WebSocket connection
    const socket = new WebSocket(`wss://${window.location.hostname}/ws?token=${token}`);
    setWs(socket);
  
    socket.onopen = () => {
      console.log('Connected to WebSocket server');
    };
  
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'WINNER_ANNOUNCEMENT') {
        setGameState(prevState => {
          const updatedWinners = [
            { username: data.payload.username, round: prevState.roundNumber },
            ...prevState.previousWinners.slice(0, 2)
          ];
    
          return {
            ...prevState,
            previousWinners: updatedWinners,
            roundNumber: prevState.roundNumber + 1
          };
        });
    
        // Trigger the animation for the new winner
        setTimeout(() => {
          const winnerItems = document.querySelectorAll('.winner-item');
          winnerItems.forEach((item, index) => {
            item.classList.remove('new'); // Remove the class first from all items
          });
          if (winnerItems[0]) {
            winnerItems[0].classList.add('new'); // Only apply the animation to the latest winner
            setTimeout(() => winnerItems[0].classList.remove('new'), 500); // Remove the 'new' class after the animation
          }
        }, 100);
      } else if (data.type === 'USER_UPDATE') {
        setUser((prevUser) => {
          if (prevUser && prevUser._id === data.payload._id) {
            return data.payload;
          }
          return prevUser;
        });
      }
    };
  
    socket.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };
  
    return () => {
      socket.close();
    };
  }, []);
  
  const handleLogout = () => {
    const token = localStorage.getItem('token');
  
    axios.post('/api/users/logout', {}, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(() => {
      localStorage.removeItem('token');
      window.location = '/'; // Redirect to home on logout
    }).catch((err) => {
      console.error('Logout failed:', err);
    });
  };

  const handleClick = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: 'PLAYER_CLICK' }));
      setButtonMessage('-1');
      setButtonClass('lose');

      // Trigger the fly-away animation
      setTimeout(() => {
        setButtonClass('fly');
      }, 50); // Delay slightly to ensure the show class applies first

      // Reset message and class after animation
      setTimeout(() => {
        setButtonMessage('');
        setButtonClass('');
      }, 250); // 200ms for the animation + a buffer
    }
  };

  const renderWinners = () => {
    if (!gameState || !gameState.previousWinners) return null;
    
    // Ensure only the last 3 winners are displayed in reverse order
    const winners = gameState.previousWinners.slice(0, 3).reverse();
  
    return (
      <div className="winners">
        <p>Awaiting winner for the round {gameState.roundNumber}</p>
        <div className="winner-list">
          {winners.map((winner, index) => (
            <span key={index} className={`winner-item show ${index === 0 ? 'new' : ''}`}>
              {winner ? `${winner.username} won round ${winner.round}` : 'No winners yet in this round'}
              {index < winners.length - 1 && ' | '}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Container>
      <div className="game-section">
        <h1>Lucky Circles</h1>
        {user && (
          <p>
            Hi, <span className="game-section__username">{user.username}</span> | 
            <span className="logout-link" onClick={handleLogout}> Logout</span>
          </p>
        )}
        {user && (
          <p>
            Balance: 
            <span className="game-section__balance-container">
              <span className="game-section__balance">{user.balance}</span>
              {buttonMessage && <span className={`click-message ${buttonClass}`}>{buttonMessage}</span>}
            </span>
          </p>
        )}

        {message && <Alert variant="info">{message}</Alert>}
        <div className="game-section__circle-button">
          <Button
            className={`click-button ${buttonClass}`}
            onClick={handleClick}
          >
            {buttonMessage && <span className="click-message show">{buttonMessage}</span>}
            {!buttonMessage && 'Click Me'}
          </Button>
        </div>
      </div>
      {renderWinners()}
      {user && user.isAdmin && (
        <Link to="/admin" className="btn btn-secondary admin-button">Admin</Link>
      )}
      {ws && user && <Chat ws={ws} user={user} />}
    </Container>
  );
};

export default Game;
