import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';

const Chat = ({ ws, user }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [activeTab, setActiveTab] = useState('all');
  const [mentions, setMentions] = useState([]);
  const allChatContainerRef = useRef(null);
  const mentionsChatContainerRef = useRef(null);

  // Fetch previous messages when component mounts
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const res = await axios.get('/api/chat/messages', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setMessages(res.data);

        // Filter messages that mention the current user
        const userMentions = res.data.filter(msg => msg.message.includes(`@${user.username}`));
        setMentions(userMentions);

        // Scroll to the bottom of the chat container
        scrollToBottom(allChatContainerRef);
        scrollToBottom(mentionsChatContainerRef);
      } catch (err) {
        console.error('Failed to fetch chat messages:', err);
      }
    };

    fetchMessages();
  }, [user]);

  useEffect(() => {
    if (ws && user) {
      const handleWsMessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'CHAT_MESSAGE') {
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages, data.payload];
            scrollToBottom(allChatContainerRef);
            return newMessages;
          });

          if (data.payload.message.includes(`@${user.username}`)) {
            setMentions((prevMentions) => {
              const newMentions = [...prevMentions, data.payload];
              scrollToBottom(mentionsChatContainerRef);
              return newMentions;
            });
          }
        }
      };

      ws.addEventListener('message', handleWsMessage);

      return () => {
        ws.removeEventListener('message', handleWsMessage);
      };
    }
  }, [ws, user]);

  const handleSendMessage = () => {
    if (ws && newMessage.trim() && user) {
      const messageToSend = newMessage.slice(0, 60); // Limit message to 60 characters
      ws.send(JSON.stringify({ type: 'CHAT_MESSAGE', message: messageToSend, username: user.username }));
      setNewMessage('');
    }
  };

  const scrollToBottom = (ref) => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  return (
    <div className="chat-container">
      <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="chat-tabs">
        <Tab eventKey="all" title="All">
          <div className="chat-messages" ref={allChatContainerRef}>
            {messages.map((msg, index) => (
              <div key={index} className="chat-message">
                <strong>{msg.username}:</strong> {msg.message}
              </div>
            ))}
          </div>
        </Tab>
        <Tab eventKey="mentions" title="Mentions">
          <div className="chat-messages" ref={mentionsChatContainerRef}>
            {mentions.map((msg, index) => (
              <div key={index} className="chat-message">
                <strong>{msg.username}:</strong> {msg.message}
              </div>
            ))}
          </div>
        </Tab>
      </Tabs>
      <div className="chat-input">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          maxLength={60} // Limit the input length directly
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default Chat;
