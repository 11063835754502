// src/components/Admin.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Container, Form, Button, Col, Row, Alert } from 'react-bootstrap';
import GameStatistics from './GameStatistics'; // Import the new component

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [refillAmount, setRefillAmount] = useState(0);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get('/api/users', {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        setUsers(res.data);
      } catch (err) {
        console.error('Failed to fetch users:', err);
      }
    };
    fetchUsers();

    const ws = new WebSocket(`wss://${window.location.hostname}/ws?token=${localStorage.getItem('token')}`);

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'USER_UPDATE') {
        setUsers((prevUsers) =>
          prevUsers.map((user) => (user._id === data.payload._id ? data.payload : user))
        );
      } else if (data.type === 'USER_DELETE') {
        setUsers((prevUsers) => prevUsers.filter((user) => user._id !== data.payload));
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  }, []);

  const handleBan = async () => {
    try {
      if (!selectedUser) return;
      await axios.post(
        '/api/users/ban',
        { userId: selectedUser.value },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
      );
      const isBanned = users.find(user => user._id === selectedUser.value).banned;
      setMessage(`User ${isBanned ? 'unbanned' : 'banned'} successfully`);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 2500); // Hide message after 2.5 seconds
      setTimeout(() => setMessage(''), 3000); // Remove message text after 3 seconds
    } catch (err) {
      console.error('Failed to ban/unban user:', err);
    }
  };

  const handleRefill = async () => {
    try {
      if (!selectedUser) return;
      await axios.post(
        '/api/users/refill',
        { userId: selectedUser.value, amount: refillAmount },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
      );
      setMessage('User balance refilled successfully');
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 2500); // Hide message after 2.5 seconds
      setTimeout(() => setMessage(''), 3000); // Remove message text after 3 seconds
    } catch (err) {
      console.error('Failed to refill balance:', err);
    }
  };

  const handleDelete = async () => {
    try {
      if (!selectedUser) return;
      await axios.delete(`/api/users/${selectedUser.value}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      setMessage('User deleted successfully');
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 2500); // Hide message after 2.5 seconds
      setTimeout(() => setMessage(''), 3000); // Remove message text after 3 seconds
      setSelectedUser(null); // Clear the selected user after deletion
    } catch (err) {
      console.error('Failed to delete user:', err);
    }
  };

  return (
    <Container style={{ maxWidth: '960px' }}>
      <h1 className="my-4">Admin Panel</h1>
      <Form>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formUsers">
              <Form.Label>Select User</Form.Label>
              <Select
                options={users.map((user) => ({ value: user._id, label: user.username, isBanned: user.banned }))}
                onChange={setSelectedUser}
                value={selectedUser}
                isClearable
                isSearchable
                styles={{
                  container: (provided) => ({ ...provided, width: '100%' }),
                  option: (provided, state) => ({
                    ...provided,
                    color: state.data.isBanned ? 'red' : 'black'
                  })
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        {selectedUser && (
          <>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="formRefill">
                  <Form.Label>Refill Points</Form.Label>
                  <Row>
                    <Col xs={8}>
                      <Form.Control
                        type="number"
                        value={refillAmount}
                        onChange={(e) => setRefillAmount(Number(e.target.value))}
                      />
                    </Col>
                    <Col xs={4}>
                      <Button variant="primary" onClick={handleRefill} className="w-100 mt-2 mt-md-0">
                        Refill
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-6">
              <Col xs={6}>
                <Button
                  variant={users.find(user => user._id === selectedUser.value).banned ? 'success' : 'danger'}
                  onClick={handleBan}
                  className="w-100"
                >
                  {users.find(user => user._id === selectedUser.value).banned ? 'Unban' : 'Ban'}
                </Button>
              </Col>
              <Col xs={6}>
                <Button variant="danger" onClick={handleDelete} className="w-100">
                  Delete User
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Row className="justify-content-md-center mb-6">
          <Col md="auto">
            <Button variant="link" onClick={() => (window.location = '/game')}>
              Back to Game
            </Button>
          </Col>
        </Row>
      </Form>
      {message && (
        <Alert variant="info" className="message-bottom">
          {message}
        </Alert>
      )}
      <GameStatistics /> {/* Use the new component here */}
    </Container>
  );
};

export default Admin;
