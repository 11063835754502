import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Pagination } from 'react-bootstrap';

const GameStatistics = () => {
  const [games, setGames] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchGames = async (page) => {
      try {
        const res = await axios.get(`/api/games?page=${page}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        setGames(res.data.games);
        setPage(res.data.page);
        setPages(res.data.pages);
        setTotal(res.data.total);
      } catch (err) {
        console.error('Failed to fetch games:', err);
      }
    };
    fetchGames(page);

    const ws = new WebSocket(`wss://${window.location.hostname}/ws?token=${localStorage.getItem('token')}`);

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'NEW_GAME_ROUND') {
        fetchGames(page); // Refresh the games when a new game round is received
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  }, [page]);

  const formatRuntime = (startTime, endTime) => {
    const diff = (new Date(endTime) - new Date(startTime)) / 1000;
    if (diff < 1) {
      return `${diff.toFixed(2)}s`;
    } else if (diff < 60) {
      return `${diff.toFixed(2)}s`;
    } else {
      const minutes = Math.floor(diff / 60);
      const seconds = (diff % 60).toFixed(2);
      return `${minutes}min ${seconds}s`;
    }
  };

  const handleExpand = (index) => {
    setGames((prevGames) =>
      prevGames.map((game, i) =>
        i === index ? { ...game, expanded: !game.expanded } : game
      )
    );
  };

  return (
    <>
      <h2 className="my-4">Game Rounds</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Round</th>
            <th>Winner</th>
            <th>Runtime</th>
            <th>Players Participated</th>
          </tr>
        </thead>
        <tbody>
          {games.map((game, index) => (
            <tr key={index}>
              <td>{game.roundNumber}</td>
              <td>{game.winnerUsername}</td>
              <td>{formatRuntime(game.startTime, game.endTime)}</td>
              <td>
                {game.playersParticipated.length > 1 ? (
                  <>
                    {game.playersParticipated[0]}+{game.playersParticipated.length - 1} others{' '}
                    <Button variant="link" onClick={() => handleExpand(index)}>
                      {game.expanded ? 'collapse' : 'expand'}
                    </Button>
                    {game.expanded && (
                      <ul>
                        {game.playersParticipated.slice(1).map((player, i) => (
                          <li key={i}>{player}</li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  game.playersParticipated[0]
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
        <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1} />
        {Array.from({ length: pages }).map((_, idx) => (
          <Pagination.Item key={idx + 1} active={idx + 1 === page} onClick={() => setPage(idx + 1)}>
            {idx + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === pages} />
        <Pagination.Last onClick={() => setPage(pages)} disabled={page === pages} />
      </Pagination>
    </>
  );
};

export default GameStatistics;
