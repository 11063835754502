import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import Logo from '../assets/LuckyCircles-Logo-Square.svg'; // Adjust path as needed
import './Home.css'; // Updated import statement

function Home() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isRegister, setIsRegister] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Apply overflow hidden to body
    document.body.style.overflow = 'hidden';

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const circle = document.querySelector('.circle');
    const logo = document.querySelector('.logo');

    const updateCircleColor = () => {
      const getRandomColor = () => {
        let color;
        do {
          color = 'rgba(' + 
            Math.floor(Math.random() * 256) + ',' +
            Math.floor(Math.random() * 256) + ',' +
            Math.floor(Math.random() * 256) + ',0.1)';
        } while (color === 'rgba(255,255,255,0.1)' || color === 'rgba(0,0,0,0.1)');
        return color;
      };

      const newColor = getRandomColor();
      circle.style.backgroundColor = newColor;

      setTimeout(() => {
        const nextColor = getRandomColor();
        circle.style.backgroundColor = nextColor;
      }, 4000);
    };

    const updateCirclePosition = () => {
      const logoRect = logo.getBoundingClientRect();
      circle.style.top = `${logoRect.top + logoRect.height / 2}px`;
      circle.style.left = `${logoRect.left + logoRect.width / 2}px`;
    };

    circle.addEventListener('animationiteration', updateCircleColor);
    updateCircleColor();
    updateCirclePosition();

    window.addEventListener('resize', updateCirclePosition);

    return () => {
      circle.removeEventListener('animationiteration', updateCircleColor);
      window.removeEventListener('resize', updateCirclePosition);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = isRegister ? '/api/users/register' : '/api/users/login';
    try {
      const res = await axios.post(endpoint, { username, password });
      localStorage.setItem('token', res.data.token);
      window.location = '/game';
    } catch (err) {
      setError(err.response.data.msg || 'An error occurred');
    }
  };

  return (
    <div className="home-container">
    <div className="container">
    <div className="pseudo-body">
      <div className="circle"></div>
      </div>
      <div className="content">
        <img src={Logo} alt="Lucky Circles" className="logo" />
        <div className="form-container">
          <Form onSubmit={handleSubmit} className="form-login">
            <Form.Control
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="form-input"
              required
            />
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-input"
              required
            />
            <Button type="submit" className="form-button">
              {isRegister ? 'Register' : 'Login'}
            </Button>
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
          </Form>
          <div className="switch-button" onClick={() => setIsRegister(!isRegister)}>
            {isRegister ? 'Switch to Login' : 'Switch to Register'}
          </div>
        </div>
      </div>
      <div className="footer">Lucky Circles</div>
    </div>
    </div>
  );
}

export default Home;
