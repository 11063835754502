import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import Game from './components/Game';
import Admin from './components/Admin';
import NotFound from './components/NotFound';
import './index.css'; // Use the global index.css

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/game" component={Game} />
          <Route path="/admin" component={Admin} />
          <Route component={NotFound} /> {/* Fallback route for 404 */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
